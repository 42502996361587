import { i18next } from '@translate/i18n'

export const STATUS_OPTIONS = [
  { value: null, text: i18next.t('maintenance.labels.all') },
  { value: 'SCHEDULED', text: i18next.t('maintenance.labels.scheduled') },
  { value: 'NO_SHOW', text: i18next.t('maintenance.labels.noShow') },
  { value: 'TO_SCHEDULE', text: i18next.t('maintenance.labels.toSchedule') },
  { value: 'CANCELED', text: i18next.t('maintenance.labels.canceled') },
  { value: 'DONE', text: i18next.t('maintenance.labels.done') },
];

export const FIELDS_DELIVERY = [
  { name: 'scheduled_at', title: i18next.t('maintenance.fields.dateScheduleDelivery'), sortField: 'scheduled_at' },
  { name: 'driver.name', title: i18next.t('maintenance.fields.name') },
  { name: 'driver.cpf', title: i18next.t('maintenance.fields.document') },
  { name: 'type', title: i18next.t('maintenance.fields.scheduleType'), sortField: 'type' },
  { name: 'status', title: i18next.t('maintenance.fields.scheduleStatus'), sortField: 'status' },
  { name: 'booking.status', title: i18next.t('maintenance.fields.bookingStatus') },
  { name: 'car.license_plate', title: i18next.t('maintenance.fields.board') }
];

export const FIELDS_DEVOLUTION = [
  { name: 'scheduled_at', title: i18next.t('maintenance.fields.dateScheduleDelivery'), sortField: 'scheduled_at' },
  { name: 'car.license_plate', title: i18next.t('maintenance.fields.board'), },
  { name: 'driver.name', title: i18next.t('maintenance.fields.name') },
  { name: 'metadata.conductor', title: i18next.t('maintenance.fields.conductor') },
  { name: 'driver.cpf', title: i18next.t('maintenance.fields.document') },
  { name: 'type', title: i18next.t('maintenance.fields.scheduleType'), sortField: 'type' },
  { name: 'status', title: i18next.t('maintenance.fields.scheduleStatus'), sortField: 'status' },
  { name: 'booking.status', title: i18next.t('maintenance.fields.bookingStatus'), },
  { name: 'place.name', title: i18next.t('maintenance.fields.place') }
];

export const TYPES = {
  REBOOKING: i18next.t('maintenance.labels.rebooking'),
  NEW_DRIVER: i18next.t('maintenance.labels.newDriver'),
  EXCHANGE_MAINTENANCE: i18next.t('maintenance.labels.exchangeMaintenance'),
  EXCHANGE_SINISTER: i18next.t('maintenance.labels.exchangeSinister'),
  EXCHANGE_APREHENSION: i18next.t('maintenance.labels.exchangeAprehension'),
  EXCHANGE_OTHERS: i18next.t('maintenance.labels.exchangeOthers'),
  EXCHANGE_PLAN: i18next.t('maintenance.labels.exchangePlan'),
  EXCHANGE_PLAN_AND_CAR: i18next.t('maintenance.labels.exchangePlanAndCar'),
  EXCHANGE_RECURRENCY: i18next.t('maintenance.labels.exchangeRecurency'),
  EXCHANGE_TOKEN: i18next.t('maintenance.labels.exchangeToken'),
  UPDATE_VEHICLE_DOCUMENT: i18next.t('maintenance.labels.updateVehicleDocument'),
  UPDATE_DRIVER_LICENSE: i18next.t('maintenance.labels.updateDriverLicense'),
  DEVOLUTION_VOLUNTARY: i18next.t('maintenance.labels.devolutionVoluntary'),
  DEVOLUTION_DEFAULT: i18next.t('maintenance.labels.devolutionDefault'),
  DEVOLUTION_MAINTENANCE: i18next.t('maintenance.labels.devolutionMaintenance'),
  DEVOLUTION_SINISTER: i18next.t('maintenance.labels.devolutionSinister'),
  DEVOLUTION_APREHENSION: i18next.t('maintenance.labels.devolutionAprehension'),
  DEVOLUTION_OTHERS: i18next.t('maintenance.labels.devolutionOthers'),
  EXCHANGE_DEMOBILIZATION: i18next.t('maintenance.labels.exchangeDemobilization'),
  UPDATE_CONTRACT: i18next.t('maintenance.labels.updateContract'),
  DEVOLUTION_FINES_POINTS: i18next.t('maintenance.labels.devolutionFinesPoints'),
  DEVOLUTION_PROMISE: i18next.t('maintenance.labels.devolutionPromise'),
  INSURANCE_INSTALLATION: i18next.t('maintenance.labels.insuranceInstallation'),
  INSURANCE_DEVOLUTION: i18next.t('maintenance.labels.insuranceDevolution'),
  INSURANCE_TRACKER_MAINTENANCE: i18next.t('drivers.forms.insuranceTrackerMaintenance.text'),
  DEMOBILIZATION: i18next.t('drivers.forms.demobilization.text'),
  CAR_WITHOUT_LICENSE_PLATE: i18next.t('drivers.forms.carWithoutLicensePlate.text'),
  EXCHANGE_LICENSE_PLATE: i18next.t('drivers.forms.exchangeLicensePlate.text'),
  CAR_RETURN_BACKUP: i18next.t('drivers.forms.carReturnBackup.text'),
  COLLECTION_SAFETY: i18next.t('drivers.forms.collectionSafety.text'),
  CAR_EXCHANGE_SAFETY: i18next.t('drivers.forms.carExchangeSafety.text'),
  COLLECTION_FRAUD_ANALYSIS: i18next.t('drivers.forms.collectionFraudeAnalysis.text'),
  ROBBERY_THEFT: i18next.t('drivers.forms.robberyTheft.text'),
  CAR_EXCHANGE_ROBBERY_THEFT: i18next.t('drivers.forms.carExchangeRobberyTheft.text'),
  APPROPRIATION: i18next.t('drivers.forms.appropriation.text'),
  COLLECTION_BREACH_CONTRACT: i18next.t('drivers.forms.collectionBreachContract.text'),
  COLLECTION_CONFIRMED_FRAUD: i18next.t('drivers.forms.collectionConfirmedFraud.text'),
  COLLISION: i18next.t('drivers.forms.collision.text'),
  CAR_EXCHANGE_COLLISION: i18next.t('drivers.forms.carExchangeCollision.text'),
  COLLISION_THIRD_PARTY: i18next.t('drivers.forms.collisionThirdParty.text'),
  EXCHANGE_COLLISION_THIRD_PARTY: i18next.t('drivers.forms.exchangeCollisionThirdParty.text'),
  NATURAL_EVENTS: i18next.t('drivers.forms.naturalEvents.text'),
  EXCHANGE_NATURAL_EVENTS: i18next.t('drivers.forms.exchangeNaturalEvents.text'),
  ROBBERY_THEFT_ATTEMPTED: i18next.t('drivers.forms.robberyTheftAttempted.text'),
  EXCHANGE_ROBBERY_THEFT_ATTEMPTED: i18next.t('drivers.forms.exchangeRobberyTheftAttempted.text'),
  INSPECTION: i18next.t('drivers.forms.inspection.text'),
  EXCHANGE_MAINTENANCE_ORIGINAL_CAR: i18next.t('drivers.forms.exchangeMaintenanceOriginalCar.text'),
  TRACKER_INSPECTION: i18next.t('drivers.forms.trackerInspection.text'),
  ZANZAR_INSPECTION: i18next.t('drivers.forms.zanzarInspection.text'),
  TINSMITHING_SCHEDULED: i18next.t('drivers.forms.tinsmithingScheduled.text'),
};

export const STATUS = {
  TO_SCHEDULE: i18next.t('maintenance.labels.toSchedule'),
  SCHEDULED: i18next.t('maintenance.labels.scheduled'),
  NO_SHOW: i18next.t('maintenance.labels.noShow'),
  CANCELED: i18next.t('maintenance.labels.canceled'),
  DONE: i18next.t('maintenance.labels.done')
};

export const BOOKING_STATUS = {
  ACTIVE: i18next.t('drivers.status.active'),
  CLOSED: i18next.t('drivers.status.closed'),
  PENDING: i18next.t('drivers.status.pending'),
  CANCELED: i18next.t('drivers.status.canceled')
};

export const CONDUCTOR = {
  DRIVER: i18next.t('drivers.forms.drivers'),
  CHOFER: i18next.t('drivers.forms.chofer'),
  TOW_TRUCKER: i18next.t('drivers.forms.towTrucker'),
  OTHERS: i18next.t('drivers.forms.others')
};
