const garageModel = {
    name: 'string',
    status: 'string',
    reseller_trusted: 'boolean',
    address_street_name: 'string',
    address_street_number: 'string',
    address_street_no_number: 'boolean',
    address_neighborhood: 'string',
    address_city: 'string',
    address_country: 'string',
    address_postal_code: 'string',
};

function _objectIsLessThenModel(objectModel, objectToCompare) {
  const modelLength = Object.keys(objectModel).length
  const passedObjectLength = Object.keys(objectToCompare).length

  if (modelLength > passedObjectLength) return false
}

function _isNoNumberAddress(address, address_street_no_number) {
  return address == 'address_street_number' && address_street_no_number
}

function _getDiferentItems(objectModel, objectToCompare) {
  return Object.keys(objectModel).filter((item) => {
    if (objectToCompare[item] === (undefined || '')) {
      return _isNoNumberAddress(item, objectToCompare.address_street_no_number) 
        ? null 
        : item
    }
    return typeof objectToCompare[item] != objectModel[item]
  })
}

export const isValidGarage = (garageObject = {}) => {

    if (_objectIsLessThenModel(garageModel, garageObject)) return false

    const diferentItems = _getDiferentItems(garageModel, garageObject)

    return !diferentItems.length
};
