import { mutate, query } from '@utils/apollo';
import listQuery from '@graphql/schedules/queries/list.gql';
import createScheduleForCarMutation from '@graphql/schedules/mutations/create-schedule-for-car.gql';
import scheduleItMutation from '@graphql/schedules/mutations/schedule-it.gql';
import reScheduleItMutation from '@graphql/schedules/mutations/reschedule-it.gql';
import finishItMutation from '@graphql/schedules/mutations/finish-it.gql';
import cancelItMutation from '@graphql/schedules/mutations/cancel-it.gql';
import cycleBackToScheduleMutation from '@graphql/schedules/mutations/cycle-back-to-schedule.gql';
import didNotAttendMutation from '@graphql/schedules/mutations/did-not-attend.gql';
import changeScheduleStatusMutation from '@graphql/schedules/mutations/change-schedule-status.gql';

export const list = (variables = {}, graphqlQuery = listQuery) => query(graphqlQuery, variables);

export const createScheduleForCar = (variables = {}, graphqlQuery = createScheduleForCarMutation) => mutate(
  graphqlQuery,
  variables,
);

export const scheduleIt = (variables = {}, graphqlQuery = scheduleItMutation) => mutate(graphqlQuery, variables);

export const reScheduleIt = ({ scheduleId, date, garage } = {}, graphqlQuery = reScheduleItMutation) => mutate(graphqlQuery, {
  scheduleId,
  date,
  garage,
});

export const finishIt = (variables = {}, graphqlQuery = finishItMutation) => mutate(graphqlQuery, variables);

export const cancelIt = (variables = {}, graphqlQuery = cancelItMutation) => mutate(graphqlQuery, variables);

export const didNotAttend = (variables = {}, graphqlQuery = didNotAttendMutation) => mutate(graphqlQuery, variables);

export const changeScheduleStatus = (variables = {}, graphqlQuery = changeScheduleStatusMutation) => mutate(graphqlQuery, variables);

export const cycleBack = (variables = {}, graphqlQuery = cycleBackToScheduleMutation) => mutate(graphqlQuery, variables);
