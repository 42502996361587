import * as Sentry from '@sentry/browser';
import {
  Vue,
  Dedupe,
  SessionTiming,
  Transaction,
  ExtraErrorData,
  RewriteFrames,
  Offline,
  CaptureConsole,
  ReportingObserver,
} from '@sentry/integrations';
import { Integrations } from "@sentry/tracing";

const getTrace = () => {
  const trace = parseFloat(Math.random().toFixed(1));
  return trace ? trace : getTrace();
}

class SentryManager {
  constructor(parameters) {
    this.dsn = parameters.dsn || '';
    this.environment = parameters.environment || '';
    this.Vue = parameters.Vue;
    this.enabled = parameters.enabled || false;
    this.config = parameters.config || {};
  }

  get captureException() {
    return Sentry.captureException;
  }

  init() {
    Sentry.init({
      dsn: this.dsn,
      environment: this.environment,
      enabled: this.enabled,
      attachStacktrace: true,
      integrations: [
        new Vue({
          Vue: this.Vue,
          attachProps: true,
          tracing: true,
          logErrors: true,
          tracingOptions: {
            trackComponents: true,
          },
        }),
        new Dedupe(),
        new ExtraErrorData(),
        new SessionTiming(),
        new Transaction(),
        new Offline(),
        new CaptureConsole({
          levels: ['error'],
        }),
        new ReportingObserver(),
        new Integrations.BrowserTracing(),
      ],
      tracesSampleRate: getTrace(),
      ...this.config,
    });
  }

  static captureException() {
    return Sentry.captureException;
  }

  static setUser({ name, email, id }) {
    Sentry.configureScope((scope) => {
      scope.setUser({
        name,
        email,
        id,
      });
    });
  }

  static setTag(tag, value) {
    Sentry.configureScope((scope) => {
      scope.setTag(tag, value);
    });
  }

  static setExtra(key, value) {
    Sentry.configureScope((scope) => {
      scope.setExtra(key, value);
    });
  }
}

export const VueSentryPlugin = {
  install(Vue, options) {
    const sentry = new SentryManager(options);

    sentry.init();

    Vue.prototype.$sentry = sentry;
  },
};

export default SentryManager;
