var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-form-group",
    { class: _vm.parentClass, attrs: { label: _vm.maskLabel } },
    [
      _c(
        "b-input-group",
        {
          class: {
            "valid-input": _vm.fieldState,
            "invalid-input": _vm.fieldState === false,
          },
          attrs: { prepend: _vm.prepend, append: _vm.append },
        },
        [
          _c("the-mask", {
            staticClass: "form-control text-right",
            class: {
              "rounded-right": !!_vm.prepend,
              "rounded-left": !!_vm.append,
              "text-uppercase": !!_vm.uppercase,
            },
            attrs: { type: "text", disabled: _vm.disabled, mask: _vm.mask },
            on: {
              input: function ($event) {
                return _vm.$emit("input", _vm.inputValue)
              },
            },
            nativeOn: {
              blur: function ($event) {
                return _vm.$emit("request")
              },
            },
            model: {
              value: _vm.getCurrentValue,
              callback: function ($$v) {
                _vm.getCurrentValue = $$v
              },
              expression: "getCurrentValue",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }