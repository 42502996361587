export const COUNTRIES = {
  BR: 'BR',
  MX: 'MX',
}

export const CURRENCY_SYMBOLS = {
  BR: 'R$',
  MX: '$',
}

export const TIMEZONE = {
  BR: 'America/Sao_Paulo',
  MX: 'America/Mexico_City'
}
