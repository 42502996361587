export const formItems = {
  INITIAL: {
    car: true,
    origin: true,
    reported_issues: true,
    garage: true,
    garage_arrival_date: true,
    schedule_date: true,
    delivery_date: true,
    estimate_cost_sent_date: true,
    finish_estimate_date: true,
    finish_date: true,
    is_driver_accountable: true,
    driver_accountable_value: true,
    car_receiver: true,
    labor_cost: true,
    parts_cost: true
  },
  SCHEDULED: {
    car: false,
    origin: false,
    reported_issues: false,
    garage: false,
    garage_arrival_date: true,
    schedule_date: false,
    delivery_date: true,
    estimate_cost_sent_date: true,
    finish_estimate_date: true,
    finish_date: true,
    is_driver_accountable: true,
    driver_accountable_value: true,
    car_receiver: true,
    labor_cost: true,
    parts_cost: true
  },
  CREATE: {
    car: false,
    origin: false,
    reported_issues: false,
    garage: false,
    garage_arrival_date: false,
    schedule_date: true,
    delivery_date: true,
    estimate_cost_sent_date: true,
    finish_estimate_date: false,
    finish_date: true,
    is_driver_accountable: false,
    driver_accountable_value: false,
    car_receiver: false,
    labor_cost: false,
    parts_cost: false
  },
  CANCELED: {
    car: true,
    origin: true,
    reported_issues: true,
    garage: true,
    garage_arrival_date: true,
    schedule_date: true,
    delivery_date: true,
    estimate_cost_sent_date: true,
    finish_estimate_date: true,
    finish_date: true,
    is_driver_accountable: true,
    driver_accountable_value: true,
    car_receiver: true,
    labor_cost: true,
    parts_cost: true
  },
  PENDING_BUDGET: {
    car: true,
    origin: true,
    reported_issues: false,
    garage: true,
    garage_arrival_date: false,
    schedule_date: true,
    delivery_date: true,
    estimate_cost_sent_date: true,
    finish_estimate_date: false,
    finish_date: true,
    is_driver_accountable: false,
    driver_accountable_value: false,
    car_receiver: false,
    labor_cost: false,
    parts_cost: false
  },
  IN_REPAIR: {
    car: true,
    origin: true,
    reported_issues: false,
    garage: true,
    garage_arrival_date: true,
    schedule_date: true,
    delivery_date: true,
    estimate_cost_sent_date: false,
    finish_estimate_date: false,
    finish_date: false,
    is_driver_accountable: false,
    driver_accountable_value: false,
    car_receiver: false,
    labor_cost: false,
    parts_cost: false,
  },
  READY_FOR_PICKUP: {
    car: true,
    origin: true,
    reported_issues: true,
    garage: true,
    garage_arrival_date: true,
    schedule_date: true,
    delivery_date: true,
    estimate_cost_sent_date: true,
    finish_estimate_date: true,
    finish_date: false,
    is_driver_accountable: false,
    driver_accountable_value: false,
    car_receiver: false,
    labor_cost: true,
    parts_cost: true
  },
  DONE: {
    car: true,
    origin: true,
    reported_issues: true,
    garage: true,
    garage_arrival_date: true,
    schedule_date: true,
    delivery_date: false,
    estimate_cost_sent_date: true,
    finish_estimate_date: true,
    finish_date: true,
    is_driver_accountable: true,
    driver_accountable_value: true,
    car_receiver: true,
    labor_cost: true,
    parts_cost: true
  }
};
