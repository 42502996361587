<script>
export default {
  name: 'MaskInput',
  model: {
    prop: 'currentValue',
    event: 'change',
  },
  props: {
    /*
     * this prop defines in witch date should the calendar allow user to pick a date.
     */
    mask: {
      type: [Array, String],
      default: '',
    },
    /*
     * Equivalent to bootstrap desabled | :hide="true" is equal to :disabled="true"
     */
    disabled: {
      type: Boolean,
      default: false,
    },
    /*
     * States for bootstrap :state | :field-state="true" is equal to :state="true"
     */
    fieldState: {
      type: Boolean,
      default: null,
    },
    /*
     * Current value that should be displayed when it initializes
     */
    currentValue: {
      required: true,
      default: '',
    },
    prepend: {
      type: String,
      default: '',
    },
    append: {
      type: String,
      default: '',
    },
    maskLabel: {
      type: [String, Array],
      default: '',
      required: true,
    },
    parentClass: {
      type: String,
      default: 'col-md-3 col-sm-12',
    },
    /**
     * This change the type of text, if will be uppercase or normal
     */
    uppercase: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    inputValue: '',
  }),
  computed: {
    getCurrentValue: {
      get() {
        return this.currentValue;
      },
      set(value) {
        this.inputValue = value;
        this.$emit('change', value);
      },
    },
  },
};
</script>

<template>
  <b-form-group :class="parentClass" :label="maskLabel">
    <b-input-group
      :prepend="prepend"
      :append="append"
      :class="{'valid-input': fieldState, 'invalid-input': fieldState === false}"
    >
      <the-mask
        v-model="getCurrentValue"
        type="text"
        class="form-control text-right"
        :disabled="disabled"
        :class="{'rounded-right': !!prepend, 'rounded-left': !!append, 'text-uppercase': !!uppercase}"
        :mask="mask"
        @blur.native="$emit('request')"
        @input="$emit('input', inputValue)"
      />
    </b-input-group>
    <!-- <div :class="[fieldState !== false ? 'hide-msg' : 'error-msg']">
      Campo obrigatório
    </div>-->
  </b-form-group>
</template>

<style scoped>
.invalid-input {
  border: 1px solid #f86c6b;
  border-radius: 5px;
}
.valid-input {
  border: 1px solid #4dbd74;
  border-radius: 5px;
}
.error-msg {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #f86c6b;
}
.hide-msg {
  display: none;
}
</style>
