<script>
import _adyen from 'adyen-cse-web';

window.adyen = _adyen;
export default {
  name: 'App',


  errorCaptured: (err, vm, info) => {
    const errorData = {
      route: vm.$route.name,
      info
    };
    if( window.newrelic ) {
      window.newrelic.noticeError( err.message, errorData);
    }

    return false;
  },

  page: {
    titleTemplate(title) {
      title = typeof title === 'function' ? title(this.$store) : title;
      const defaultTitle = 'Kovi Rental';
      return title ? `${title} - ${defaultTitle}` : defaultTitle;
    },
  }
};
</script>

<template>
  <div id="app">
    <vue-snotify />
    <router-view :key="$route.fullPath" />
  </div>
</template>

<style lang="sass">
@import '~nprogress/nprogress.css'
$fa-font-path: '~font-awesome/fonts/'
@import '~font-awesome/scss/font-awesome.scss'
@import '~bootstrap-vue/dist/bootstrap-vue.css'
@import '~vue-multiselect/dist/vue-multiselect.min.css'
@import 'assets/scss/style'
@import './assets/texts/index.scss'
@import '~vue-snotify/styles/material'
@import '~viewerjs/dist/viewer.css'
*
  list-style: none
.snotify
  width: 500px
</style>
