import { query } from '@utils/apollo';
import entityRegionQuery from '@graphql/user/queries/entity-region.gql';

export const fetchEntityRegionService = async (
  {
    entity,
    id
  } = {},
  graphqlQuery = entityRegionQuery,
) => await query(graphqlQuery, {
  query: {
    entity,
    id,
  }
});
