<script>
import { currentLanguage } from '@utils/language';

export default {
  name: 'KoviDatePicker',
  date: () => ({
    date: Date.now(),
  }),
  model: {
    prop: 'currentDate',
    event: 'change',
  },
  props: {
    notAfter: {
      type: [String, Date],
      default: ''
    },
    notBefore: {
      type: [String, Date],
      default: ''
    },
    hide: {
      type: Boolean,
      default: true,
    },
    fieldState: {
      type: Boolean,
      default: null,
    },
    currentDate: {
      type: [String, Date],
      default: ''
    },
  },
  computed: {
    getLanguage() {
      return currentLanguage;
    },
    getCurrentDate: {
      get() {
        return this.currentDate;
      },
      set(value) {
        if (!value) return;
        const formatDate = this.$moment(value)
          .utc()
          .format('YYYY-MM-DD HH:mm:ss');
        this.date = formatDate;
        this.$emit('change', formatDate);
      },
    },
    getNotBefore() {
      return this.notBefore ? this.$moment(this.notBefore).toDate() : null;
    },
  },
};
</script>

<template>
  <div>
    <datepicker
      id="booking-started"
      v-model="getCurrentDate"
      v-bind="$attrs"
      width="100%"
      :not-after="notAfter"
      :not-before="getNotBefore"
      :lang="getLanguage"
      :class="{ 'valid-date': fieldState, 'invalid-date': fieldState === false }"
      format="DD/MM/YYYY"
      :disabled="hide"
    />
    <div :class="{ 'error-msg': fieldState === false, 'hide-msg': true }">
      Campo obrigatório
    </div>
  </div>
</template>

<style scoped>
.invalid-date {
  border: 1px solid #f86c6b;
  border-radius: 5px;
}
.valid-date {
  border: 1px solid #4dbd74;
  border-radius: 5px;
}
.error-msg {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #f86c6b;
}
.hide-msg {
  display: none;
}
</style>
