import { i18next } from '@translate/i18n'

export const statusItems = {
  INITIAL: [
    {
      text: i18next.t('corrective.enums.noValue'),
      value: null,
      disabled: true,
    },
    {
      text: i18next.t('corrective.enums.scheduled'),
      value: 'SCHEDULED',
      disabled: false,
    },
    {
      text: i18next.t('corrective.enums.pendingBudget'),
      value: 'PENDING_BUDGET',
      disabled: false,
    },
  ],
  SCHEDULED: [
    {
      text: i18next.t('corrective.enums.scheduled'),
      value: 'SCHEDULED',
      disabled: true,
    },
    {
      text: i18next.t('corrective.enums.pendingBudget'),
      value: 'PENDING_BUDGET',
      disabled: false,
    },
    {
      text: i18next.t('corrective.enums.canceled'),
      value: 'CANCELED',
      disabled: false,
    }
  ],
  CANCELED: [
    {
      text: i18next.t('corrective.enums.canceled'),
      value: 'CANCELED',
      disabled: true,
    }
  ],
  PENDING_BUDGET: [
    {
      text: i18next.t('corrective.enums.pendingBudget'),
      value: 'PENDING_BUDGET',
      disabled: true,
    },
    {
      text: i18next.t('corrective.enums.inRepair'),
      value: 'IN_REPAIR',
      disabled: false,
    },
    {
      text: i18next.t('corrective.enums.canceled'),
      value: 'CANCELED',
      disabled: false,
    }
  ],
  IN_REPAIR: [
    {
      text: i18next.t('corrective.enums.inRepair'),
      value: 'IN_REPAIR',
      disabled: true,
    },
    {
      text: i18next.t('corrective.enums.readyForPickup'),
      value: 'READY_FOR_PICKUP',
      disabled: false,
    },
    {
      text: i18next.t('corrective.enums.canceled'),
      value: 'CANCELED',
      disabled: false,
    }
  ],
  READY_FOR_PICKUP: [
    {
      text: i18next.t('corrective.enums.readyForPickup'),
      value: 'READY_FOR_PICKUP',
      disabled: true,
    },
    {
      text: i18next.t('corrective.enums.done'),
      value: 'DONE',
      disabled: false,
    }
  ],
  DONE: [
    {
      text: i18next.t('corrective.enums.done'),
      value: 'DONE',
      disabled: true,
    }
  ]
};
