import { class2obj } from '@utils/class2obj';

export class Entity {
  constructor() {}

  static of () {
    throw new Error('Static method not implemented');
  }

  /**
   *
   * @returns {object}
   */
  toObject() {
    return class2obj(this);
  }

  update() {
    throw new Error('Update method not implemented');
  }
}
