export default {
  Auth: {
    identityPoolId: process.env.AWS_AUTH_IDENTITY_POOL_ID,
    identityPoolRegion: process.env.AWS_REGION,
    region: process.env.AWS_REGION,
    userPoolId: process.env.AWS_AUTH_USER_POOL_ID,
    userPoolWebClientId: process.env.AWS_AUTH_POOL_WEB_CLIENT_ID,
    mandatorySignIn: process.env.AWS_AUTH_MANDATORY_SIGNIN,
    redirectSignIn: `${location.origin}/auth/login`,
    redirectSignOut: `${location.origin}/auth/logout`
  },
  Storage: {
    bucket: process.env.AWS_STORAGE_BUCKET,
    region: process.env.AWS_REGION,
    level: process.env.AWS_STORAGE_LEVEL,
  },
  Analytics: {
    disabled: process.env.AWS_ANALYTICS_DISABLED,
    appId: process.env.AWS_ANALYTICS_APP_ID,
    region: process.env.AWS_REGION,
  },
  API: {
    endpoints: [
      {
        name: 'API',
        endpoint: process.env.KOVI_API_URL,
      },
    ],
  },
}
