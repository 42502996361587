import * as Sentry from '@sentry/browser';

export const LogLevelName = {
  Info: 'info',
  Warn: 'warn',
  Error: 'error',
  Method: 'method',
  All: 'all',
  None: 'none',
};

export const LogLevelStyle = {
  Info: 'background:#00D1C1 ; padding: 2px; border-radius: 2px;  color: #fff;',
  Warn: 'background:#FFB400 ; padding: 2px; border-radius: 2px;  color: #000;',
  Error: 'background:#FFAA91 ; padding: 2px; border-radius: 2px;  color: #fff;',
  Method: 'background:#BCE071 ; padding: 2px; border-radius: 2px;  color: #fff;',
};

const detectNode = Object.prototype.toString.call(typeof process !== 'undefined' ? process : 0) === '[object process]';

/**
 * Abstract class to be used for log
 */

const log = (
  name,
  style,
  className,
  classStyle,
  message,
) => {
  if (detectNode) {
    console.log(`${className}[${name}]: ${Array.isArray(message) ? message.join(' | ') : message}`);
  } else {
    console.log(`%c ${name} %c ${className} %c ${
      Array.isArray(message) ? message.join(' | ') : message
    }`, style, classStyle, 'background: transparent;');
  }
};

const logError = (
  name,
  style,
  className,
  classStyle,
  message,
) => {
  if (detectNode) {
    console.error(`${className}[${name}]: `, message);
  } else {
    console.error(`%c ${name} %c ${className} %c ${message}`,
      style,
      classStyle,
      'background: transparent;');
  }
};

/**
 * LogManager Class
 */

class logManager {
  constructor(parameters) {
    /**
     * Class name for using with the log
     */
    this.className = parameters.className || 'Kovi - Rental';
    /**
     * Log style to be used when the log is called
     */
    this.logStyle = parameters.className || 'background:#ff355a ; padding: 2px; border-radius: 2px;  color: #fff;';
    /**
     * Show the logs on the console
     */
    this.showLogs = parameters.showLogs || LogLevelName.None;
  }

  get verboseMode() {
    return this.showLogs === LogLevelName.Info || this.showLogs === LogLevelName.All;
  }

  /**
   * Log an info event
   */
  logError(error) {
    if (this.showLogs === LogLevelName.Error || this.showLogs === LogLevelName.All) {
      if (error instanceof Error && error.message) {
        logError(
          LogLevelName.Error,
          LogLevelStyle.Error,
          this.className,
          this.logStyle,
          error.message,
        );
      } else {
        const err = Error(`${typeof error === 'object' ? JSON.stringify(error) : error}`);

        logError(
          LogLevelName.Error,
          LogLevelStyle.Error,
          this.className,
          this.logStyle,
          err,
        );
      }
    }
    Sentry.captureException(error)
  }

  /**
   * Log an info event
   */
  logInfo(...message) {
    if (
      this.showLogs === LogLevelName.Info
      || this.showLogs === LogLevelName.All
    ) {
      log(
        LogLevelName.Info,
        LogLevelStyle.Info,
        this.className,
        this.logStyle,
        message,
      );
    }
  }

  /**
   * Log an method call
   */
  logMethod(method, args, ...params) {
    if (
      this.showLogs === LogLevelName.Method
      || this.showLogs === LogLevelName.All
    ) {
      log(
        LogLevelName.Method,
        LogLevelStyle.Method,
        this.className,
        this.logStyle,
        `Call Method: ${method}(${args ? args : ''}) ${(params) ? `=> ${JSON.stringify(params)}` : ''}`,
      );
    }
  }

  /**
   * Log an info event
   */
  logWarn(...warn) {
    if (this.showLogs === LogLevelName.Warn
      || this.showLogs === LogLevelName.All
    ) {
      log(
        LogLevelName.Warn,
        LogLevelStyle.Warn,
        this.className,
        this.logStyle,
        warn,
      );
    }
  }
}

export const VueLogManagerPlugin = {
  install(Vue, options) {
    Vue.prototype.$log = new logManager(options)
  }
};

export default logManager;
