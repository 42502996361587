import moment from 'moment-timezone';
import checkCPF from '@brazilian-utils/is-valid-cpf';
import apolloClient from '@utils/graphql';
import EventBus from '@utils/eventBus';
import { emailPattern } from '@utils/email';
import DRIVER_UPDATE from '@graphql/driver/mutations/update.gql';
import { Entity } from './entity';

export const status = {
  LEAD: 'LEAD',
  PENDING_DOCS: 'PENDING_DOCS',
  PENDING_BOOKING: 'PENDING_BOOKING',
  PENDING_PAYMENT: 'PENDING_PAYMENT',
  PENDING_CAR: 'PENDING_CAR',
  ACTIVE: 'ACTIVE',
  SIGNUP: 'SIGNUP',
  BANNED: 'BANNED',
};

export const steps = {
  WAITING_LIST: 'WAITING_LIST',
  REJECTED: 'REJECTED',
  LEAD: 'LEAD',
  APPS_PENDING: 'APPS_PENDING',
  DOCS_PENDING: 'DOCS_PENDING',
  DOCS_REJECTED: 'DOCS_REJECTED',
  DOCS_CHECKING: 'DOCS_CHECKING',
  DOCS_APPROVED: 'DOCS_APPROVED',
  INVITE_PENDING: 'INVITE_PENDING',
  INVITE_EXPIRED: 'INVITE_EXPIRED',
  INVITE_SENT: 'INVITE_SENT',
  PAYMENT_PENDING_PAYMENT: 'PAYMENT_PENDING_PAYMENT',
  PAYMENT_PENDING: 'PAYMENT_PENDING',
  PLAN_PENDING: 'PLAN_PENDING',
  DELIVERY_PENDING: 'DELIVERY_PENDING',
  DELIVERY_SCHEDULED: 'DELIVERY_SCHEDULED',
  DELIVERY_NOSHOW: 'DELIVERY_NOSHOW',
  NO_OPERATION: 'NO_OPERATION',
  FINISHED: 'FINISHED',
};

export const associatedSteps = {
  WAITING_LIST: [steps.WAITING_LIST, steps.REJECTED],
  DOCS_PENDING: [steps.APPS_PENDING, steps.DOCS_PENDING, steps.DOCS_REJECTED],
  INVITE_PENDING: [steps.DOCS_APPROVED, steps.INVITE_PENDING, steps.INVITE_EXPIRED, steps.INVITE_SENT],
  PAYMENT_PENDING: [steps.PAYMENT_PENDING_PAYMENT, steps.PAYMENT_PENDING, steps.PLAN_PENDING],
  DELIVERY_PENDING: [steps.DELIVERY_PENDING, steps.DELIVERY_SCHEDULED, steps.DELIVERY_NOSHOW],
  DELIVERY_SCHEDULED: [steps.DELIVERY_SCHEDULED, steps.DELIVERY_NOSHOW]
};

export const hooks = {
  updated: 'DRIVER_UPDATED',
};

export class DriverEntity extends Entity {
  constructor(payload = {}) {
    super();
    this.checked_at = payload.checked_at;
    this.updated_at = payload.updated_at;
    this.created_at = payload.created_at;
    this.address_city = payload.address_city;
    this.address_country = payload.address_country;
    this.address_latlong = payload.address_latlong;
    this.address_neighborhood = payload.address_neighborhood;
    this.address_postal_code = payload.address_postal_code;
    this.address_state = payload.address_state;
    this.address_street_details = payload.address_street_details;
    this.address_street_name = payload.address_street_name;
    this.address_street_no_number = payload.address_street_no_number;
    this.address_street_number = payload.address_street_number;
    this.birthdate = payload.birthdate;
    this.cnh_doc = payload.cnh_doc;
    this.code2fa = payload.code2fa;
    this.cpf = payload.cpf;
    this.email = payload.email;
    this.email_verified = payload.email_verified;
    this.emergency_1_name = payload.emergency_1_name;
    this.emergency_1_phone_number = payload.emergency_1_phone_number;
    this.emergency_2_name = payload.emergency_2_name;
    this.emergency_2_phone_number = payload.emergency_2_phone_number;
    this.garage_address = payload.garage_address;
    this.garage_other_address = payload.garage_other_address;
    this.gender = payload.gender;
    this.id = payload.id;
    this.license_category = payload.license_category;
    this.license_city = payload.license_city;
    this.license_expired_at = payload.license_expired_at;
    this.license_first_issued_at = payload.license_first_issued_at;
    this.license_has_permission = payload.license_has_permission;
    this.license_issued_at = payload.license_issued_at;
    this.license_number = payload.license_number;
    this.license_points = payload.license_points;
    this.license_remarks = payload.license_remarks;
    this.license_state = payload.license_state;
    this.license_status = payload.license_status;
    this.license_status_detail = payload.license_status_detail;
    this.mother_name = payload.mother_name;
    this.father_name = payload.father_name;
    this.name = payload.name;
    this.unsubscribed = payload.unsubscribed;
    this.nickname = payload.nickname;
    this.phone_number = payload.phone_number;
    this.phone_number_verified = payload.phone_number_verified;
    this.photo = payload.photo;
    this.status = payload.status;
    this.step = payload.step;
    this.survey_app_99 = payload.survey_app_99;
    this.survey_app_cabify = payload.survey_app_cabify;
    this.survey_app_lady_driver = payload.survey_app_lady_driver;
    this.survey_app_others = payload.survey_app_others;
    this.survey_app_uber = payload.survey_app_uber;
    this.survey_be_over_21 = payload.survey_be_over_21;
    this.survey_has_garage = payload.survey_has_garage;
    this.survey_low_points = payload.survey_low_points;
    this.work_city = payload.work_city;
    this.fiscal_number = payload.fiscal_number
  }

  static of(object = {}) {
    return new DriverEntity(object);
  }

  set checked_at(value) {
    if (!value) {
      this._checked_at = null;
      return;
    }

    this._checked_at = moment(value);
  }

  get checked_at() {
    return this._checked_at;
  }

  set updated_at(value) {
    if (!value) {
      this._updated_at = null;
      return;
    }

    this._updated_at = moment(value);
  }

  get updated_at() {
    return this._updated_at;
  }

  set created_at(value) {
    if (!value) {
      this._created_at = null;
      return;
    }

    this._created_at = moment(value);
  }

  get created_at() {
    return this._created_at;
  }

  /**
   * is pending docs
   * @returns {boolean}
   */
  isPendingDocs() {
    return this.status === status.PENDING_DOCS;
  }

  /**
   * check if is a valid e-mail
   * @returns {boolean}
   */
  isValidEmail() {
    return emailPattern.test(this.email);
  }

  /**
   * check is valid CNH
   * @returns {boolean}
   */
  isValidCNH() {
    return this.license_number
      ? this.license_number.length === 11
      : false;
  }

  /**
   * check if is a valid cpf
   * @returns {boolean}
   */
  isValidCpf() {
    return checkCPF(this.cpf);
  }

  /**
   * return true if has a emergency data completed
   * @returns {boolean}
   */
  hasEmergencyData() {
    return !!this.emergency_1_name && !!this.emergency_1_phone_number;
  }

  /**
   * update driver and active hook
   * @returns {Promise<DriverEntity>}
   */
  update() {
    return apolloClient.mutate({
      mutation: DRIVER_UPDATE,
      variables: {
        ...this.toObject(),
      },
    }).then(({ data }) => {
      const { updateDriver } = data;
      if (!updateDriver)
        throw new Error('Driver was returned null');

      let driverEntity = DriverEntity.of(updateDriver);
      EventBus.$emit('DRIVER_UPDATED', driverEntity);
      return driverEntity;
    });
  }
}
