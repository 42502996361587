var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "datepicker",
        _vm._b(
          {
            class: {
              "valid-date": _vm.fieldState,
              "invalid-date": _vm.fieldState === false,
            },
            attrs: {
              id: "booking-started",
              width: "100%",
              "not-after": _vm.notAfter,
              "not-before": _vm.getNotBefore,
              lang: _vm.getLanguage,
              format: "DD/MM/YYYY",
              disabled: _vm.hide,
            },
            model: {
              value: _vm.getCurrentDate,
              callback: function ($$v) {
                _vm.getCurrentDate = $$v
              },
              expression: "getCurrentDate",
            },
          },
          "datepicker",
          _vm.$attrs,
          false
        )
      ),
      _c(
        "div",
        { class: { "error-msg": _vm.fieldState === false, "hide-msg": true } },
        [_vm._v(" Campo obrigatório ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }