import moment from 'moment-timezone';
import gql from 'graphql-tag';
import apolloClient from '@utils/graphql';
import {Entity} from './entity';
import EventBus from '@utils/eventBus';

export const DriverDocumentEvents = {
  approved: 'DRIVER_DOCUMENT_APPROVED',
  disapproved: 'DRIVER_DOCUMENT_DISAPPROVED'
};

export const DriverDocumentTypes = {
  cnh: 'CNH',
  address: 'ADDRESS',
  garage: 'GARAGE',
  appUber: 'APP_UBER',
  app99: 'APP_99',
  appCabify: 'APP_CABIFY',
  appLadyDriver: 'APP_LADYDRIVER',
  appOthers: 'APP_OUTROS',
};

export const DriverDocumentStatus = {
  pending: 'PENDING',
  sent: 'SENT',
  rejected: 'REJECTED',
  approved: 'APPROVED',
};

export class DriverDocumentEntity extends Entity {
  constructor(payload = {}) {
    super();
    this.id = payload.id;
    this.driver_id = payload.driver_id;
    this.status = payload.status;
    this.type = payload.type;
    this.url = payload.url;
    this.reject_reasons = payload.reject_reasons;
    this.created_at = payload.created_at;
    this.updated_at = payload.updated_at;
  }

  static of(object = {}) {
    return new DriverDocumentEntity(object);
  }

  get created_at() {
    return this._created_at;
  }

  set created_at(value) {
    this._created_at = moment(value);
  }

  get updated_at() {
    return this._updated_at;
  }

  set updated_at(value) {
    this._updated_at = moment(value);
  }

  isTypeCnh() {
    return this.type === DriverDocumentTypes.cnh;
  }

  isTypeAddress() {
    return this.type === DriverDocumentTypes.address;
  }

  isTypeGarage() {
    return this.type === DriverDocumentTypes.garage;
  }

  isTypeAppUber() {
    return this.type === DriverDocumentTypes.appUber;
  }

  isTypeApp99() {
    return this.type === DriverDocumentTypes.app99;
  }

  isTypeAppCabify() {
    return this.type === DriverDocumentTypes.appCabify;
  }

  isTypeAppLadyDriver() {
    return this.type === DriverDocumentTypes.appLadyDriver;
  }

  isTypeAppOthers() {
    return this.type === DriverDocumentTypes.appOthers;
  }

  isType(types = []) {
    return types.includes(this.type);
  }

  isStatusPending() {
    return this.status === DriverDocumentStatus.pending;
  }

  isStatusSent() {
    return this.status === DriverDocumentStatus.sent;
  }

  isStatusRejected() {
    return this.status === DriverDocumentStatus.rejected;
  }

  isStatusApproved() {
    return this.status === DriverDocumentStatus.approved;
  }

  approve() {
    return apolloClient.mutate({
      mutation: gql`
        mutation DocumentApproved($id: ID!) {
          approve(id: $id)
        }
      `,
      variables: {
        id: this.id,
      },
    }).then(result => {
      EventBus.$emit(DriverDocumentEvents.approved, result);
      return result;
    });
  }

  disapprove() {
    return apolloClient.mutate({
      mutation: gql`
        mutation DocumentApproved($id: ID!) {
          reject(id: $id)
        }
      `,
      variables: {
        id: this.id,
      },
    }).then(result => {
      EventBus.$emit(DriverDocumentEvents.disapproved, result);
      return result;
    });
  }
}
