import { mutate, query } from '@utils/apollo';
import listQuery from '@graphql/garages/queries/list.gql';
import findByDistanceQuery from '@graphql/garages/queries/find-by-distance.gql';
import createGarageMutation from '@graphql/garages/mutations/create.gql';
import updateGarageMutation from '@graphql/garages/mutations/update.gql';

export const list = (variables = {}, graphqlQuery = listQuery) => query(graphqlQuery, variables);

export const findByDistance = ({
  latLong,
  distance,
  filter
} = {},
  graphqlQuery = findByDistanceQuery,
) => query(graphqlQuery, {
  latLong,
  distance,
  filter
});

export const create = (variables = {}, graphqlQuery = createGarageMutation) => mutate(graphqlQuery, variables);

export const update = (variables = {}, graphqlQuery = updateGarageMutation) => mutate(graphqlQuery, variables);
